import './App.css';
import {Route, Routes} from "react-router-dom";
import Post from './Post';
import Layout from './Layout';
import AdminLoginPage from './pages/AdminLoginPage';
import { UserContextProvider } from './UserContext';
import CreatePostPage from './pages/CreatePostPage';
import IndexPage from './pages/IndexPage';
import EditPostPage from './pages/EditPostPage';
import StaffPage from './pages/StaffPage';
import TreatmentPage from './pages/TreatmentPage';
import FaqPage from './pages/FaqPage';
import LocationPage from './pages/LocationPage';

function App() {
  return (
    <UserContextProvider>
    <Routes>
      <Route path="/" element={<Layout/>}>
        <Route index element={<IndexPage/>}/>
        <Route path={'/staff'} element={<StaffPage/>}/>
        <Route path={'/treatment'} element={<TreatmentPage/>}/>
        <Route path={'/location'} element={<LocationPage/>}/>
        <Route path={'/faq'} element={<FaqPage/>}/>
        <Route path={'/adminlogin'} element={<AdminLoginPage/>}/>
        <Route path={'/create'} element={<CreatePostPage/>}/>
        <Route path={'/edit/:id'} element={<EditPostPage/>}/>
      </Route>  
    </Routes>
    </UserContextProvider>


  );
}

export default App;
