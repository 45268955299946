import {useContext, useState} from 'react'
import {Navigate} from 'react-router-dom'
import { UserContext } from '../UserContext';

export default function AdminLoginPage(){
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState(false);
    const { setUserInfo } = useContext(UserContext);

    async function login(ev){
        ev.preventDefault();
        const response = await fetch('https://physiowebsite.onrender.com/adminlogin', {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: {'Content-Type' : 'application/json'},
            credentials: 'include',
        });
        console.log(response);


        if (response.ok) {
            response.json().then(userInfo => {
                setUserInfo(userInfo);
                setRedirect(true);
            });
        } else{
            alert('Invalid Credentials');
        }
    }

    if(redirect) {
        return <Navigate to={'/'} />
    }

    return(
        <form className="login" onSubmit={login}>
            <h2>Admin Login</h2>
            <input 
                type="text" 
                placeholder="Username"
                value={username}
                onChange={ev => setUsername(ev.target.value)}/>
            <input 
                type="password" 
                placeholder="Password"
                value={password}
                onChange={ev => setPassword(ev.target.value)}/>
            <button>Login</button>
        </form>
    );
}