import { useEffect, useState } from 'react';
import Post from '../Post';

export default function FaqPage() {
    const [posts, setPosts] = useState([]);

    //runs when home page is mounted
    useEffect(() => {
        fetch('https://physiowebsite.onrender.com/faq').then(response => {
            response.json().then(posts => {
            setPosts(posts);
            });
        });
    }, []);
    
    return(
        <>
            {posts.length > 0 && posts.map((post) =>(
                <Post key={post._id} {...post}/>
            ))}
        </>
    );
}