
import { useContext, useEffect, useState } from "react";
import { Link, Navigate} from "react-router-dom";
import { UserContext } from "./UserContext";
import logoImage from "./images/logo.png"

export default function Header(){
  const {setUserInfo, userInfo} = useContext(UserContext);
  const [redirect, setRedirect] = useState(false);

  useEffect(() =>{
    fetch('https://physiowebsite.onrender.com/profile', {
      credentials: 'include',
    }).then(response => {
      response.json().then(userInfo => {
        setUserInfo(userInfo);
      });
    });
  }, []);

  function logout(){
    fetch('https://physiowebsite.onrender.com/logout', {
      credentials: 'include',
      method: 'POST',
    });
    setUserInfo(null);
    setRedirect(true);
  }

  if(redirect){
    setRedirect(false);
    return <Navigate to={'/'}/>
  }

  const username = userInfo?.username;

    return(
        <header>
        <img src={logoImage} style={{width: 60}} className="logo"></img>
        <a href="/" className="logo">Athabasca Physical <br className="title-break"></br> Therapy & Acupuncture</a>
        <nav>
          {username && (
            <>
              <Link to="/">About Us</Link>
              <Link to="/location">Find Us</Link>
              <Link to="/staff">Staff</Link>
              <Link to="/treatment">Treatments</Link>
              <Link to="/faq">FAQs</Link>
              <Link to='/create'> Create Post</Link>
              <a onClick={logout}>Logout</a>
            </>
          )}

          {!username && (
            <>
              <Link to="/">About Us</Link>
              <Link to="/location">Find Us</Link>
              <Link to="/staff">Staff</Link>
              <Link to="/treatment">Treatments</Link>
              <Link to="/faq">FAQs</Link>
            </>
          )}
        </nav>
      </header>
    );
}