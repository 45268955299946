import { Link } from "react-router-dom";

export default function Footer(){
    return(
        <footer>
          <div className="footer-info">
            <Link to="/adminlogin" className="admin-link">Admin Login</Link>
          </div>
      </footer>
    );
}