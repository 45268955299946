import { useEffect, useState } from "react";
import ReactQuill from "react-quill"
import { Navigate, useParams } from "react-router-dom";
import Editor  from "../Editor"

export default function EditPostPage(){

    const {id} = useParams();
    const [page, setPage] = useState('about');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [files, setFiles] = useState('');
    const [redirect, setRedirect] = useState(false);

    useEffect(()=>{
        fetch('https://physiowebsite.onrender.com/post/'+id)
        .then(response =>{
            response.json().then(postInfo =>{
                setTitle(postInfo.title);
                setContent(postInfo.content);
                setPage(postInfo.page);
                setFiles()
            })
        })
    }, []);
    
    async function updatePost(ev){
        const data = new FormData();
        data.set('page', page);
        data.set('title', title);
        data.set('content', content);
        data.set('id', id);

        if(files?.[0]){
            data.set('file', files?.[0]);
        }
        ev.preventDefault();

        const response = await fetch('https://physiowebsite.onrender.com/update', {
            method: 'PUT',
            body: data,
            credentials: 'include',
        });

        if(response.ok){
            setRedirect(true);
        }
    }

    if(redirect){
        if(page === 'about'){
            return <Navigate to={'/'}/>
        }
        else{
            return <Navigate to={'/'+page}/>
        }
    }

    return(
        <form className='create-post' onSubmit={updatePost}>
        <h3>Edit Post</h3>
        <div className='page-selector'>
        <label>Page: </label>
        <select name="pages" id="pages" value={page} placeholder='Pages' onChange={ev =>setPage(ev.target.value)}>
            <option value="about">About</option>
            <option value="staff">Staff</option>
            <option value="treatment">Treatment</option>
            <option value="faq">FAQ</option>
        </select>
        </div>

        <input 
            type="title" 
            placeholder={'Title'} 
            value={title}
            onChange={ev => setTitle(ev.target.value)}></input>
        <input 
            type="file"
            name='file'
            onChange={ev => setFiles(ev.target.files)}/>
            <Editor onChange={setContent} value={content}/>
        <button style={{marginTop:'5px'}}>Update Post</button>
    </form>
    );
}