const { createContext, useState } = require("react");

export const UserContext = createContext({
    userInfo: null,
    setUserInfo: () => {},
});

export function UserContextProvider({children}){
    const [userInfo, setUserInfo] = useState(null);
    return(
    <UserContext.Provider value={{userInfo, setUserInfo}}>
        {children}
    </UserContext.Provider>
    );
}