
export default function LocationPage() {

    return(
        <>
        <div className='location'>

            <div className='hours'>
            <h2>Hours of Operation</h2>
            <table>
                <tbody>
                    <tr><th>Sunday</th><td>Closed</td></tr>
                    <tr><th>Monday</th><td>7:30am - 5:30pm</td></tr>
                    <tr><th>Tuesday</th><td>7:30am - 5:30pm</td></tr>
                    <tr><th>Wednesday</th><td>7:30am - 5:30pm</td></tr>
                    <tr><th>Thursday</th><td>7:30am - 5:30pm</td></tr>
                    <tr><th>Friday</th><td>7:30am - 2:00pm</td></tr>
                    <tr><th>Saturday</th><td>Closed</td></tr>
                </tbody>
            </table>
            </div>
            <div className="contact">
            <h2>Contact Us</h2>
            <table>
                <tbody>
                    <tr><th>Phone</th><td>(780) 675-1992</td></tr>
                    <tr><th>FAX</th><td>(780) 675-1950</td></tr>
                    <tr><th>Email</th><td>athapta@telus.net</td></tr>
                </tbody>
            </table>
            </div>
            </div>

            <div className='map'>
                <h2>Location</h2>
                <div className="map-info">
                <p>5410-50th Avenue Athabasca, Alberta T9S 1L4
                </p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2304.358919360063!2d-113.29559702329617!3d54.720901270995505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a3d719ff3f8711%3A0xf67ffd4576306de4!2s5410%2050%20Ave%2C%20Athabasca%2C%20AB%20T9S%201L4!5e0!3m2!1sen!2sca!4v1690850441196!5m2!1sen!2sca" 
                    allowFullScreen
                    title="googlemap">
                </iframe>
                </div>
            </div>


        </>
    );
}

//AIzaSyCBSKXu6UbVhcIQwwp9NtYEPZuwMIqRSZ4