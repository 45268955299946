
import { sanitize } from 'dompurify';
import { Link } from 'react-router-dom';
import { useContext, useEffect } from "react";
import { UserContext } from "./UserContext";

export default function Post({_id, title, content, imagePath, page}){

  const {setUserInfo, userInfo} = useContext(UserContext);

  const handleDelete = () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this post?');

    if(confirmDelete){
      fetch(`https://physiowebsite.onrender.com/posts/${_id}`, {
        method: 'DELETE'
      })
        .then(response => {
          if (response.ok) {
            // Handle success, such as showing a success message or updating the UI
            window.location.reload();
            alert("Post: "+ title +" has been deleted");
          } else {
            throw new Error('Failed to delete the post');
          }
        })
        .catch(error => {
          // Handle error, such as showing an error message
        });
  };
    }
  

  useEffect(() =>{
    fetch('https://physiowebsite.onrender.com/profile', {
      credentials: 'include',
    }).then(response => {
      response.json().then(userInfo => {
        setUserInfo(userInfo);
      });
    });
  }, []);

  const username = userInfo?.username;

    return(        
    <div className={page+'-post'}>

        <div className='title-row'>
          <h2>{title}</h2>
          {username && ( 
            <div className='post-btns'>        
          <Link className="edit-btn" to={`/edit/${_id}`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
            </svg>
            Edit Post
          </Link> 
          
          <button className='delete-btn' onClick={handleDelete}>
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 64 64" width="48px" height="48px">
              <path d="M 28 9 C 26.895 9 26 9.895 26 11 L 26 12 L 14 12 C 12.896 12 12 12.896 12 14 C 12 15.104 12.896 16 14 16 L 50 16 C 51.104 16 52 15.104 52 14 C 52 12.896 51.104 12 50 12 L 38 12 L 38 11 C 38 9.895 37.105 9 36 9 L 28 9 z M 15 18 L 15 46 C 15 49.309 17.691 52 21 52 L 43 52 C 46.309 52 49 49.309 49 46 L 49 18 L 15 18 z M 22.5 22 C 23.328 22 24 22.671 24 23.5 L 24 44.5 C 24 45.329 23.328 46 22.5 46 C 21.672 46 21 45.329 21 44.5 L 21 23.5 C 21 22.671 21.672 22 22.5 22 z M 32 22 C 33.104 22 34 22.896 34 24 L 34 44 C 34 45.104 33.104 46 32 46 C 30.896 46 30 45.104 30 44 L 30 24 C 30 22.896 30.896 22 32 22 z M 41.5 22 C 42.328 22 43 22.671 43 23.5 L 43 44.5 C 43 45.329 42.328 46 41.5 46 C 40.672 46 40 45.329 40 44.5 L 40 23.5 C 40 22.671 40.672 22 41.5 22 z"/>
            </svg>
          </button>
          </div>
          )}
        </div>

        <div className='post-content'>
            <div className='postImage'> 
            <img src={imagePath} alt='' />
            </div>
          <div className='post-text' dangerouslySetInnerHTML={{__html: sanitize(content)}}></div>
        </div>

      </div>
  );
}