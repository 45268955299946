import { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Navigate } from 'react-router-dom';
import Editor from '../Editor';

export default function CreatePostPage(){
    const [page, setPage] = useState('about') //empty is default to index or "about" page
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [files, setFiles] = useState('');
    const [redirect, setRedirect] = useState(false);

    console.log(document.cookie);

    const extractJwtFromCookie = () => {
        const jwtCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('token='));
        const extractedJwt = jwtCookie ? jwtCookie.split('=')[1] : null;
        return extractedJwt;
      };

    async function createNewPost(ev){
        const jwtToken = extractJwtFromCookie();
        if(!jwtToken){
            console.error('JWT not found in cookie.')
        }

        const data = new FormData();
        data.set('page', page)
        data.set('title', title);
        data.set('content', content);
        data.set('file', files[0])
        ev.preventDefault();
        
        const response = await fetch('https://physiowebsite.onrender.com/post',{ //might need to be athabascaphysicaltherapy.ca?
        method: 'POST',
        body: data,
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${jwtToken}`
        },
    });
    console.log(response);
    if(response.ok){
        setRedirect(true);
    }
    }

    if(redirect){
        if(page === 'about'){
            return <Navigate to={'/'}/>
        }
        else{
            return <Navigate to={'/'+page}/>
        }
    }


    return(
        <form className='create-post' onSubmit={createNewPost}>
            <div className='page-selector'>
            <label>Page: </label>
            <select name="pages" id="pages" placeholder='Pages' onChange={ev =>setPage(ev.target.value)}>
                <option value="about">About</option> 
                <option value="staff">Staff</option>
                <option value="treatment">Treatment</option>
                <option value="faq">FAQ</option>
            </select>
            </div>

            <input 
                type="title" 
                placeholder={'Title'} 
                onChange={ev => setTitle(ev.target.value)}></input>
            <input 
                type="file"
                name='file'
                onChange={ev => setFiles(ev.target.files)}/>
            <Editor value={content} onChange={setContent}/>
            <button style={{marginTop:'5px'}}>Create Post</button>
        </form>
    );
}   